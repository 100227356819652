import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 上半場</h2>
          <h1 className="main_title">彭永翔</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
                <div className="context">
                  <p>彭永翔以一個編輯的經歷，從設計媒體角度看社宅公共藝術，以三個層面來討論社宅公共藝術：</p>
                  <p>(一)身為編輯如何思考公共藝術<br/>(二)案例分析<br/>(三)結論/提出想像</p>
                  
                  <br/>
                  <h5>(一)身為編輯如何思考公共藝術</h5>
                  <div className="context onset_left_1_article">
                    <h6>詢問1：怎麼樣才是好公共藝術</h6>
                    <p>他從以下兩個面向思考這個問題</p>
                    <p><strong>1. 作品議題與形式</strong></p>
                    <ul className="context_list order_list ">
                      <li>Lewis Biggs提出好的藝術具有敢挑戰大眾思考的格局</li>
                      <li>南條史生認為藝術真正定義不是外表美觀問題，而是它必須有新的想法，而這些新的想法必須跟現在社會傳統價值觀有所不同</li>
                      <li>Daneil Buren認為藝術品不一定要引起衝擊，但是一定要提出一些疑問。否則一般人經過它而無視它，是沒有意義的</li>
                    </ul>
                  </div>
                  <div className="context onset_left_1_article">
                    <p><strong>2. 社會性</strong></p>
                    <p>公共藝術是否需要具備社會性</p>
                    <ul className="context_list order_list ">
                      <li>南條史生認為參與式設計不一定是標準答案。他認為如果藝術將社會的邀請做為首要目的，最後藝術就會失去他存在的理由，即失去藝術的純粹性</li>
                      <li>康旻杰另一方面則說公共藝術必須具配高度的社會性，讓民間的參與能量與藝術創作互相激盪，召喚藝術的公共性</li>
                    </ul>
                  </div>
                  <div className="context onset_left_1_article">
                    <h6>詢問2：社宅公共藝術和一般公共藝術的差別</h6>
                    <p>受眾鎖定為社宅住民與周邊住民，因此必須與他們產生關係，而這個關係必須是很深厚的。</p>
                  </div>

                  <br/>
                  <h5>(二)案例分析</h5>
                  <div className="context onset_left_1_article">
                    <h6>1. Project row houses</h6>
                    <ul className="context_list order_list ">
                      <li>最初目的為修繕老房子，將它們改造為畫廊或是藝術家駐村基地</li>
                      <li>但是在長期待在這個地方後，發現那裡有很多年輕、單親的未婚媽媽，因此這個團隊發起：年輕媽媽駐村計畫(YRP)</li>
                      <li>YRP提供就職課程以及一年居所，而這些年輕媽媽反之需要協助這裡營運</li>
                      <li>YRP的營運資金來自政府與企業的補助</li>
                    </ul>
                    <p><strong>提出：公共藝術是否也可以成為社會的有機體</strong></p>
                    <p>公共藝術不一定要是具體的作品，也可以是永續的文化事業。</p>
                  </div>
                  <div className="context onset_left_1_article">
                    <h6>2. Granby Four Streets, Assemble</h6>
                    <p>Assemble (2015 turner prize winner)是一個跨領域工作團隊，其所有作品都是把參與式設計放在裡面。Granby Four Streets的重新改建 (最有名的計畫)</p>
                    <ul className="context_list order_list">
                      <li>社區需要公共空間凝聚共識感</li>
                      <li>挑了兩棟最差的房子變成花園(利用殘破屋瓦等劣勢成優勢)，並邀請當地居民一起種植植栽</li>
                      <li>10 houses on Cairns Street——修繕10棟老屋，讓它是負擔地起的房子 (一半為出租£498 for rent/£99,000 for sale)</li>
                      <li>成立了工作坊，利用周邊廢棄老屋磁磚Up-cycle/recycle再製成室內裝飾等</li>
                    </ul>
                    <p>如此的社會設計有囊括當地文化於其中</p>
                  </div>
                  
                  <br/>
                  <h5>(三)結論/提出想像</h5>
                  <div className="context onset_left_1_article">
                    <p>以上的敘述看似著重在社區營造、社區設計，不像是公共藝術。重點即是形式不重要，藝術是沒有框架的，重點是它是否符合居民的需求。</p>
                    <p>015年有人質疑為何Assemble這樣的設計團隊可以得獎時，評審Alistair Hudson說：「再什麼都可以是藝術的時代，為何城市計畫不能是藝術。」</p>
                    <p>期望公共藝術在議題上可以勇敢批判，但是在批判和衝擊反感中藝術家要有其拿捏；形式上公共藝術可以更自由；如果為了讓藝術家或事社會設計團隊建立與社區的深厚連結，希望法規上可以跨越過時間的限制。</p>
                  </div>
                </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_6" className="next_page">
            <div className="next_title">主持人評論與講者回應</div>
            <div className="next_owner">20 X 20 / 上半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
